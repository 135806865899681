import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0),
    new Menu (2, 'Properties', '/properties', null, null, false, 0), 
    // new Menu (40, 'Pages', null, null, null, true, 0),
    // new Menu (41, 'Agents', '/agents', null, null, false, 40), 
    // new Menu (42, 'Agent', '/agents/1', null, null, false, 40),
    // new Menu (43, 'Login', '/login', null, null, false, 40), 
    // new Menu (44, 'Register', '/register', null, null, false, 40), 
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'Pricing', '/pricing', null, null, false, 40), 
    // new Menu (47, 'Terms & Conditions', '/terms-conditions', null, null, false, 40), 
    // new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    // new Menu (50, '404 Page', '/404', null, null, false, 40),  
    new Menu (60, 'Contact', '/contact', null, null, false, 0),  
    // new Menu (70, 'About Us', '/about', null, null, false, 0),   
    // new Menu (140, 'Others', null, null, null, true, 40),
    // new Menu (141, 'External Link', null, 'http://themeseason.com', '_blank', false, 140), 
    // new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    // new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    // new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140)    
]

export const verticalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0), 
    new Menu (2, 'Properties', '/properties', null, null, false, 0), 
    // new Menu (40, 'Pages', null, null, null, true, 0),
    // new Menu (41, 'Agents', '/agents', null, null, false, 40), 
    // new Menu (42, 'Agent', '/agents/1', null, null, false, 40), 
    // new Menu (43, 'Login', '/login', null, null, false, 40), 
    // new Menu (44, 'Register', '/register', null, null, false, 40),   
    // new Menu (45, 'FAQs', '/faq', null, null, false, 40),
    // new Menu (46, 'Pricing', '/pricing', null, null, false, 40), 
    // new Menu (47, 'Terms & Conditions', '/terms-conditions', null, null, false, 40),  
    // new Menu (48, 'Landing', '/landing', null, null, false, 40), 
    // new Menu (50, '404 Page', '/404', null, null, false, 40),
    new Menu (60, 'Contact', '/contact', null, null, false, 0),  
    // new Menu (70, 'About Us', '/about', null, null, false, 0),   
    // new Menu (140, 'Level 1', null, null, null, true, 0),
    // new Menu (141, 'Level 2', null, null, null, true, 140),
    // new Menu (142, 'Level 3', null, null, null, true, 141),
    // new Menu (143, 'Level 4', null, null, null, true, 142),
    // new Menu (144, 'Level 5', null, 'http://themeseason.com', null, false, 143),
    // new Menu (200, 'External Link', null, 'http://themeseason.com', '_blank', false, 0)
]